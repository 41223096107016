import React, {Component} from 'react'
import {Card, Row, Col, Button, Typography, Statistic, Radio, Modal, Collapse} from 'antd'
import {Link} from "react-router-dom"
import {isMobile} from 'react-device-detect'
import Content from './сontentModal'
const {Panel} = Collapse;
const {Text} = Typography

const moreDetails = (item, key, selfClass) => {
    const pricing = Object.assign([], item.pricing);
    let maxAmount = 1000,
        pricingMutant = [],
        block = false;

    if (item.maxAmount) maxAmount = item.maxAmount;
    pricing.forEach((price, idx) => {
        if (!block) {
            let prev = pricing[idx - 1];
            if (!prev) {
                prev = {
                    maxAmount: item.numberTransportUnits - 1,
                    price: price.price
                }
            }
            if (price.maxAmount - prev.maxAmount == 1) {
                pricingMutant.push({
                    string: price.maxAmount,
                    price: price.price
                })
            } else {
                if (price.maxAmount >= maxAmount) {
                    pricingMutant.push({
                        string: (Number(prev.maxAmount) + item.numberTransportUnits) + "-" + maxAmount,
                        price: price.price
                    })
                    pricingMutant.push({
                        string: "более " + (maxAmount + item.numberTransportUnits),
                        price: "договор"
                    })
                    block = true;
                } else {
                    pricingMutant.push({
                        string: (Number(prev.maxAmount) + item.numberTransportUnits) + "-" + (price.maxAmount),
                        price: price.price
                    })
                }
            }
        }
    });

    let dialog = {
        visible: true,
        title: item.name,
        contet: <Content item={item} content={pricingMutant} addAmountItem={selfClass.addAmountItem} />
    }
    selfClass.setState({dialog})
}

class Items extends Component {
    constructor(props) {
        super(props)

        this.state = {
            imageMaxHeight: 0,
            maxHeight: 0,
            maxWidth: 0,
            showGroup: 0,
            dialog: {
                visible: false,
                title: null,
                contet: null
            }
        }

        this.Titles = [];
        this.Images = [];

        this.handleChangeGroup = this.handleChangeGroup.bind(this)
    }

    componentDidMount = () => {
        this.oneHeight();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.dataSource !== prevProps.dataSource) {
            this.oneHeight();
        }
    }

    oneHeight = () => {
        try {
            const self = this;
            let maxHeight = 0;
            let maxWidth = 0;

            self.Titles.forEach(function (val) {
                if (maxHeight < val.offsetHeight) {
                    maxHeight = val.offsetHeight;
                }
                if (maxWidth < val.offsetWidth) {
                    maxWidth = val.offsetWidth;
                }
            })

            self.setState({maxHeight, maxWidth})

            try {
                setTimeout(function () {
                    let imageMaxHeight = 0;
                    self.Images.forEach(function (val) {
                        if (imageMaxHeight < val.clientHeight) {
                            imageMaxHeight = val.clientHeight;
                        }
                    })
                    if (imageMaxHeight > 100) {
                        self.setState({imageMaxHeight})
                    }
                }, 300)
            } catch (e) {

            }
        } catch (e) {

        }
    }

    handleChangeGroup = (e) => {
        let value = e.target.value;
        this.setState({showGroup: value})
    }

    handleCloseModal = () => {
        const self = this;
        let dialog = Object.assign({}, self.state.dialog);
        dialog.visible = false;
        self.setState({dialog})
    }

    addAmountItem = (item, amount) => {
        this.props.addAmountItem(item, amount);
        this.handleCloseModal();
    }

    render() {
        const ts = this;
        const {imageMaxHeight, maxHeight, maxWidth, showGroup, dialog} = this.state;
        const {dataSource, getItemPrice, addItem, addedItems, group} = this.props;
        let span = isMobile ? 12 : 6;

        return (
            <div>
                {
                    isMobile ? (
                        <Collapse className="collapse-phone-box" expandIconPosition="right">
                            <Panel header="Фильтр" key="1">
                                <Radio.Group defaultValue={showGroup} size={isMobile ? "default" : "large"} onChange={this.handleChangeGroup}>
                                    <Radio value={0} key="0">Все</Radio>
                                    {Object.keys(group).map(function (key, indx) {
                                        return <Radio value={group[key].id} key={key}>{group[key].name}</Radio>
                                    })}
                                </Radio.Group>
                            </Panel>
                        </Collapse>
                    ) : (
                        <Row className="text-center pb-20">
                            <Radio.Group defaultValue={showGroup} size={isMobile ? "default" : "large"} onChange={this.handleChangeGroup}>
                                <Radio.Button value={0} key="0">Все</Radio.Button>
                                {Object.keys(group).map(function (key, indx) {
                                    return <Radio.Button value={group[key].id} key={key}>{group[key].name}</Radio.Button>
                                })}
                            </Radio.Group>
                        </Row>
                    )
                }
                <Row>
                    {dataSource.map(function (value, index) {
                        let imgSrc = "/public/images/null.png";
                        if (value.image) {
                            imgSrc = value.image;
                        }

                        let dataPrice = getItemPrice(value);
                        let isAdded = addedItems[value.id] ? true : false;

                        let disabledClass = '';
                        if (value.groupId !== showGroup && showGroup !== 0) {
                            disabledClass = "hide";
                        }

                        let actions = [
                            <Button size={isMobile ? "default" : "default"} onClick={() => addItem(value, dataPrice.key)} className="mutated-button">
                                Добавить
                            </Button>,
                        ];
                        if (value.moreDetails) {
                            actions = [
                                <Button size={isMobile ? "default" : "default"} onClick={() => moreDetails(value, dataPrice.key, ts)} className="mutated-button">
                                    Подробнее
                                </Button>,
                            ]
                        }
                        if (isAdded) {
                            actions = [
                                <Link to="/order">
                                    <Button size={isMobile ? "default" : "default"} type="danger" className="mutated-button">
                                        В корзину
                                    </Button>
                                </Link>,
                            ];
                        }

                        return (
                            <Col key={index} span={span} className={"pr-5 pl-5 pb-10 nomenclature-card " + disabledClass + " " + (isAdded ? "added" : "")}>
                                <Card
                                    hoverable
                                    cover={(
                                        <div
                                            className="box-image-in-card"
                                            ref={(node) => {ts.Images[index] = node}}
                                            style={{height: imageMaxHeight > 0 ? imageMaxHeight : "auth", width: maxWidth > 0 ? maxWidth + 30 : "auth"}}
                                        >
                                            <img alt={value.name} src={imgSrc} />
                                        </div>
                                    )}
                                    actions={actions}
                                >
                                    <div className="nomenclature-card-info">Товар добавлен</div>
                                    <div
                                        ref={(node) => {ts.Titles[index] = node}}
                                        style={{height: maxHeight > 0 ? maxHeight : "auth", width: maxWidth > 0 ? maxWidth : "auth"}}
                                        className="box"
                                    >
                                        <div className="title">
                                            {value.name}
                                        </div>
                                        <div className="amount">
                                            {
                                                !value.moreDetails ? (
                                                    <Statistic
                                                        value={(value.numberTransportUnits * dataPrice.price) + ".руб"}
                                                        suffix={<Text type="secondary">
                                                            за {value.numberTransportUnits} {value.unit}
                                                        </Text>}
                                                    />
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
                <Row>
                    <Col span={24} className="text-center mt-25">
                        <Link to="/order">
                            <Button className="mutated-button" type="danger" block={isMobile ? true : false} size={isMobile ? "default" : "large"}>
                                Вернуться к оформлению заявки
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Modal
                    visible={dialog.visible}
                    title={dialog.title}
                    onCancel={ts.handleCloseModal}
                    footer={null}
                >
                    {dialog.contet}
                </Modal>
            </div>
        )
    }
}
export default Items;

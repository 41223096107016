import React from 'react';
import {Form, Input} from 'antd';
import InputMask from 'react-input-mask';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from "../../dialogTitle";

const DialogEditPhone = ({handleClose, handleSubmit, open, data}) => {
    if (!open) return null;

    const [form] = Form.useForm();

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth="xs">
            <DialogTitle id="simple-dialog-title" onClose={handleClose}>
                {data.id?"Редактирование":"Добавление"} телефона
            </DialogTitle>
            <DialogContent>
                <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{
                    id: data.id?data.id:0,
                    phone: data.phone?data.phone:null,
                }}>
                    <Form.Item name="id" className="none">
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item label="Телефон *" name="phone" rules={[{ required: true, message: 'Поле обязательно для заполнения!'}]}>
                        <InputMask autoFocus={true} mask="+7 (999) 999-99-99" size="large">
                            {(inputProps) => <Input {...inputProps} placeholder="+7 (___) ___-__-__" />}
                        </InputMask>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="secondary"
                            size="large"
                            fullWidth={true}
                            >
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form>
            </DialogContent>
        </Dialog>
    )
}
export default DialogEditPhone;

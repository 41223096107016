import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from "./../dialogTitle";

const DialogOrder = ({handleClose, open, children, form}) => {
    return (
        <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="simple-dialog-title-address" open={open} fullWidth={true}>
            <DialogTitle onClose={handleClose} id="simple-dialog-title-address">
                Возвращаемая тара
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default DialogOrder